<template lang="html">
  <v-container fluid>
    <v-row>
      <v-col>
        <base-material-card icon="mdi-account-group">
          <template v-slot:heading>
            <h1 class='display-2'>Sasaran Kinerja yang Sudah Ditutup</h1>
          </template>
          <v-toolbar flat>
            <div style="max-width:400px">
              <v-autocomplete v-model="selectSKPD" :items="skpd" label="SKPD" item-text="v" item-value="k" hide-details></v-autocomplete>
            </div>
            <div style="max-width:400px" class="mx-5">
              <v-text-field v-model="search" hide-details label="Cari NIP" append-icon="mdi-magnify"></v-text-field>
            </div>
            <v-spacer></v-spacer>
          </v-toolbar>
          <v-data-table
            :headers="headers"
            :items="items"
            :page.sync="page"
            :items-per-page.sync="rowPerPage"
            :footer-props="{'items-per-page-options':[5,10,15]}"
            :server-items-length="totalKeseluruhan"
            :loading="loading"
          >
          <template v-slot:item.no="{item, index}">
              {{index+1}}
            </template>
          <template v-slot:item.periode="{item}">
            <b>SKP Periode {{item.periode_awal_bln}} - {{item.periode_akhir_bln}}</b>
          </template>
          <template v-slot:item.alasan="{item}">
            <b>{{ item.alasan == 'mutasi' ? 'Mutasi Jabatan' : (item.alasan == 'alih tugas' ? 'Alih Tugas' : item.alasan == 'tahunan' ? 'Tutup SKP Akhir Tahun' : '') }}</b>
          </template>
          <template v-slot:item.nama="{item}">
            <b>{{ item.peg_nama }} ({{item.nip}})</b>
          </template>
          <template v-slot:item.atasan="{item}">
            <b>{{ item.atasan.peg_nama }} ({{item.nip_atasan}})</b>
          </template>
          <template v-slot:item.aksi="{item}">
            <br>
            <v-btn x-small color="yellow darken-1" @click="lihatSasaran(item)"><v-icon small>mdi-account-search</v-icon> &nbsp;Lihat SKP</v-btn><br>
            <!-- <v-btn x-small color="primary" @click="bukaSkp(item)"><v-icon small>mdi-login</v-icon> &nbsp;Buka SKP</v-btn> -->
            <confirm-btn
              :width="'auto'"
              :title="'Buka SKP '+tahun+' a.n. '+ item.peg_nama + ' - ' + item.nip + '?'"
              content="<p style='color:white'>Apakah Anda Yakin Akan <b>Membuka</b> SKP Ini?</p>"
              :data="item"
              :btnProps="{name:'Buka SKP', color:'brown', icon:'mdi-check', xSmall:true, class:{'pl-2':true} }"
              :cardProps="{color:'brown'}"
              @on-agree="bukaSkp">
            </confirm-btn><br><br>
            <div v-if="tahun < 2022">
              <v-btn x-small color="secondary" @click="exportSkp(item)"><v-icon small>mdi-file-excel</v-icon> &nbsp;Export SKP</v-btn>
            </div>
            <div v-else>
              <v-btn x-small @click="exportPPKPNS(1, item)" color="cyan darken-4" dark width="min-content" class="mr-0 mt-2">
                <span> <v-icon small left>mdi-file-pdf-box</v-icon> Download PPKPNS TW1</span>
              </v-btn>
              <v-btn x-small @click="exportPPKPNS(2, item)" color="cyan darken-3" dark width="min-content" class="mr-0 mt-2">
                <span> <v-icon small left>mdi-file-pdf-box</v-icon> Download PPKPNS TW2</span>
              </v-btn>
              <v-btn x-small @click="exportPPKPNS(3, item)" color="cyan darken-2" dark width="min-content" class="mr-0 mt-2">
                <span> <v-icon small left>mdi-file-pdf-box</v-icon> Download PPKPNS TW3</span>
              </v-btn>
              <v-btn x-small @click="exportPPKPNS(4, item)" color="cyan darken-1" dark width="min-content" class="mr-0 mt-2">
                <span> <v-icon small left>mdi-file-pdf-box</v-icon> Download PPKPNS TW4</span>
              </v-btn>
              <v-btn x-small @click="exportPPKPNS('tahun', item)" color="cyan" dark width="min-content" class="mr-0 mt-2">
                <span> <v-icon small left>mdi-file-pdf-box</v-icon> Download PPKPNS Tahunan</span>
              </v-btn>
              <br>
              <br>
              <v-btn x-small @click="exportPenilaianKinerja(1, item)" color="primary" class="mr-0 mt-2">
                <span> <v-icon small left>mdi-file-excel-box</v-icon> Download Dokumen Evaluasi Kinerja TW1 </span>
              </v-btn>
              <v-btn x-small @click="exportPenilaianKinerja(2, item)" color="primary lighten-1" class="mr-0 mt-2">
                <span> <v-icon small left>mdi-file-excel-box</v-icon> Download Dokumen Evaluasi Kinerja TW2 </span>
              </v-btn>
              <v-btn x-small @click="exportPenilaianKinerja(3, item)" color="primary lighten-2" class="mr-0 mt-2">
                <span> <v-icon small left>mdi-file-excel-box</v-icon> Download Dokumen Evaluasi Kinerja TW3 </span>
              </v-btn>
              <v-btn x-small @click="exportPenilaianKinerja(4, item)" color="primary lighten-3" class="mr-0 mt-2">
                <span> <v-icon small left>mdi-file-excel-box</v-icon> Download Dokumen Evaluasi Kinerja TW4 </span>
              </v-btn>
              <v-btn x-small @click="exportPenilaianKinerja('tahun', item)" color="primary lighten-4" class="mr-0 mt-2">
                <span> <v-icon small left>mdi-file-excel-box</v-icon> Download Dokumen Evaluasi Kinerja Tahunan </span>
              </v-btn>
              <br>
              <br>
              <v-btn x-small @click="exportPendokumentasianKinerja(1, item)" color="green darken-4 white--text" class="mr-0 mt-2">
                <span> <v-icon small left>mdi-file-excel-box</v-icon> Download Pendokumentasian Kinerja TW1</span>
              </v-btn>
              <v-btn x-small @click="exportPendokumentasianKinerja(2, item)" color="green darken-3 white--text" class="mr-0 mt-2">
                <span> <v-icon small left>mdi-file-excel-box</v-icon> Download Pendokumentasian Kinerja TW2</span>
              </v-btn>
              <v-btn x-small @click="exportPendokumentasianKinerja(3, item)" color="green darken-2 white--text" class="mr-0 mt-2">
                <span> <v-icon small left>mdi-file-excel-box</v-icon> Download Pendokumentasian Kinerja TW3</span>
              </v-btn>
              <v-btn x-small @click="exportPendokumentasianKinerja(4, item)" color="green darken-1 white--text" class="mr-0 mt-2">
                <span> <v-icon small left>mdi-file-excel-box</v-icon> Download Pendokumentasian Kinerja TW4</span>
              </v-btn>
              <v-btn x-small @click="exportPendokumentasianKinerja('tahun', item)" color="green white--text" class="mr-0 mt-2">
                <span> <v-icon small left>mdi-file-excel-box</v-icon> Download Pendokumentasian Kinerja Tahunan</span>
              </v-btn>
            </div>
            <br>
            <br>
          </template>
          </v-data-table>
        </base-material-card>
      </v-col>
    </v-row>
    <v-dialog v-model="dialogLihatSKP">
      <LihatSKP :dataSKP="dataSKP" :selected="selected" @onClose="(x)=>{dialogLihatSKP=x}" @onTableChange="lihatSasaran" :loadingLihatSKP="loadingLihatSKP" :totalKeseluruhan="totalKeseluruhan" :nilaiTotalCapaian="nilai_total_capaian"/>
    </v-dialog>
  </v-container>
</template>

<script>
import _ from 'lodash'
// import Crud from '@/components/common/Crud'
import SKPService from '@/services/SKPService'
import store from '@/store'
import _g from '../../global'
import axios from 'axios'
import LihatSKP from './LihatSKP.vue'
import ConfirmBtn from '@/components/base/ConfirmBtn'

export default {
  components:{
    // Crud,
    LihatSKP,
    ConfirmBtn
  },

  data(){
    return{
      forceUpdate:false,
      loading:false,
      detailData:{},

      headers:[
        {text:'No', value:'no', sortable:false, class:'header-index-profile'},
        {text:'Tanggal Tutup', value:'tanggal_tutup', sortable:false, class:'header-index-profile', width:"12%"},
        {text:'Periode', value:'periode', sortable:false, class:'header-index-profile', width:"18%"},
        {text:'Alasan Tutup', value:'alasan', sortable:false, class:'header-index-profile'},
        {text:'Nama Pegawai', value:'nama', sortable:false, class:'header-index-profile'},
        {text:'Jabatan Saat Tutup SKP', value:'jabatan_nama', sortable:false, class:'header-index-profile'},
        {text:'Atasan', value:'atasan', sortable:false, class:'header-index-profile'},
        {text:'Jabatan Atasan', value:'jabatan_nama_atasan', sortable:false, class:'header-index-profile'},
        {value:'aksi', sortable:false, class:'header-index-profile'},
      ],
      items:[],

      dialogReset:false,
      crudSettings:{
        title:'',
        detailTitle:'Detail',
        enableDetail:false,
        enableCreate:false,
      },
      rowPerPage:0,
      page:1,
      totalKeseluruhan:0,
      selectSKPD:'',
      skpd:[],
      search: '',
      dialogLihatSKP: false,
      dataSKP: [],
      loadingLihatSKP: false,
      nilai_total_capaian: null,
      selected: {},
      tahun: null
    }
  },

  watch:{
    page(val){
      // untuk memastikan tidak reload halaman awal
      // if (val > 1){
        // this.loading = true
        this.tableValue.page = val
        this.updateTable(this.tableValue)
      // }
    }
  },

  mounted(){
    // let kode_skpd = this.kode_skpd ? this.kode_skpd : local.getLocal("kolok")
    // this.getDataPegawaiSKPD(kode_skpd)
    // this.getDataStrukturPokja(null)
    this.$watch(vm => [vm.rowPerPage, vm.search, vm.selectSKPD], val => {
      this.page = 1
      this.updateTable({itemsPerPage:this.rowPerPage, kode_skpd:this.selectSKPD, search:this.search, page:this.page})
    })
  },

  created(){
    let val = {itemsPerPage:10, page: 1}
    this.updateTable(val)
    this.getListSKPD()
    this.tahun = this.$store.getters["user/data"].tahun
  },

  computed:{},

  methods:{
    getImg(nip,ext){
      if (nip){
        let avatarImgUrl = process.env.VUE_APP_IMG_URL + nip +"."+ext
        // this.avatarImages[nip] = avatarImgUrl
        return avatarImgUrl
      }
      return ''
    },

    updateTable(val){
      this.loading = true
      this.tableValue = val ? val: this.tableValue
      let user = store.state.user
      this.items = []
      SKPService.getListTutupSKP({
        role:user.current.role,
        search:this.tableValue.search,
        kode_skpd:this.tableValue.kode_skpd,
        row_per_page:this.tableValue.itemsPerPage,
        page:this.tableValue.page})
      .then((response)=>{
        this.items = response.data.data
        this.totalKeseluruhan = response.data.data.length
        console.log(this.items)
      }).finally(()=>{
        this.loading = false
      })

      // var url = "v1/admin-get-list-tutup-skp"
      // let current = store.state.user.current
      // // let user = store.state.user
      // if(user.current.role.includes("PLT") || user.current.role.includes("Kepala UPT Puskesmas")){
      //   current.username = _g.overrideWithPLT(user.current.role)
      // }else{
      //   current.username = user.current.username
      // }
      // var base_url_api = _g.getBaseURLAPIERK(current.year)
      // const url_ = base_url_api + url
      // axios.get(url_).then(response => {
      //   this.items = response.data.data
      // }).catch(err => {
      //   var error = err.message
      //   store.commit('snackbar/setSnack',{message: error, color:'error'})
      // })
    },

    getListSKPD(){
      var url = "v1/get-data-skpd2"
      let current = store.state.user.current
      let user = store.state.user
      if(user.current.role.includes("PLT") || user.current.role.includes("Kepala UPT Puskesmas") || user.current.role.includes("PLH")){
        current.username = _g.overrideWithPLT(user.current.role)
      }else{
        current.username = user.current.username
      }
      var base_url_api = _g.getBaseURLAPIERK(current.year)
      const url_ = base_url_api + url
      axios.post(url_).then(response => {
        let data = []
        _.each(response.data.data, function(value, key, obj){
          data.push({k:key, v: value})
        })
        this.skpd = data
      }).catch(err => {
        var error = err.message
        store.commit('snackbar/setSnack',{message: error, color:'error'})
      })
    },

    lihatSasaran(item){
      this.loadingLihatSKP = true
      let current = store.state.user.current
      var base_url_api = _g.getBaseURLAPIERK(current.year)
      const url_ = base_url_api + 'v1/get-sasaran-kinerja-tutup-admin'
      this.selected = item
      axios.post(url_, {api:1, nip: item.nip, id:item.id_sasaran_kinerja}).then(response => {
        this.loadingLihatSKP = false
        this.dataSKP = []
        this.dataSKP = response.data.data
        this.totalKeseluruhan = response.data.data.length
        response.data.data.forEach(el => {
          if(el.nilai_total_capaian != null){
            this.nilai_total_capaian = el.nilai_total_capaian
          }
        });
        this.dialogLihatSKP = true
      }).catch(err => {
        this.loadingLihatSKP = false
        var error = err.message
        store.commit('snackbar/setSnack',{message: error, color:'error'})
      })
    },

    bukaSkp(item){
      if(!confirm('Jika SKP Dibuka Maka Atasan Ybs Harus Melakukan Validasinya Kembali. Apakah Anda Yakin Akan Membuka SKP Ini?')) return;
      var url = "v1/buka-skp-tahunan-admin"
      let current = store.state.user.current
      let user = store.state.user
      if(user.current.role.includes("PLT") || user.current.role.includes("Kepala UPT Puskesmas") || user.current.role.includes("PLH")){
        current.username = _g.overrideWithPLT(user.current.role)
      }else{
        current.username = user.current.username
      }
      var base_url_api = _g.getBaseURLAPIERK(current.year)
      const url_ = base_url_api + url
      axios.post(url_, {id:item.id, id_sasaran_kinerja: item.id_sasaran_kinerja, admin: current.username}).then(response => {
        let res = response.data
        this.dialogEditTargetRealisasi = false
        if(res.success){
          store.commit('snackbar/setSnack',{message: res.message, color:'success'})
          this.updateTable()
        } else {
          store.commit('snackbar/setSnack',{message: res.message, color:'error'})
        }
      }).catch(err => {
        var error = err.message
        store.commit('snackbar/setSnack',{message: error, color:'error'})
      })
    },

    exportPPKPNS(triwulan, item){
      // var i_ = i
      // var t_ = triwulan
      SKPService.insertPenilaianSKPPermenpan6(triwulan, item.nip).then(response=>{
        let res =response.data
        // Permenpan No 8 2021
        if(res.success){
          var current = this.$store.getters["user/current"]
          // var date = new Date()
          // var bulan = date.getMonth()
          var year = current.year
          var urls = ''
          if(triwulan == 'tahun'){
            urls = process.env.VUE_APP_API_URL+"/export-pdf/1/2/?tipe=tahunan&thn="+year+"&crc="+res.crc
          }else{
            urls = process.env.VUE_APP_API_URL+"/export-pdf/1/2/?twn="+triwulan+"&tipe=triwulan&thn="+year+"&crc="+res.crc
          }
          window.open(urls,'_blank');
        }else{
          store.commit('snackbar/setSnack',{message: res.message, color:'error'})
        }
      }).finally(()=>{

      })
    },
    exportPenilaianKinerja(triwulan, item){
      var current = this.$store.getters["user/current"]
      var nip = item.nip //nip
      var year = current.year
      // var role = current.role
      var urls = ""
      // var base = 'https://erk-demo.rev.web.id/'
      var base = process.env.VUE_APP_API_URL
      if(base === 'https://mangbagja-demo.rev.web.id/'){
        base = 'https://erk-demo.rev.web.id/'
      }else{
        base = 'https://kinerja.bandung.go.id/'
      }
      if(triwulan != 'tahun'){
        urls = base+year+"/api/export-tahunan-permenpan-6-triwulan?nip="+nip+"&api=1&triwulan="+triwulan; //permenpan 6 2022
      }else{
        urls = base+year+"/api/export-tahunan-permenpan-6?nip="+nip+"&api=1"; //permenpan 6 2022
      }
      window.open(urls,'_blank');
    },
    exportPendokumentasianKinerja(triwulan, item){
      var current = this.$store.getters["user/current"]
      var nip = item.nip //nip
      var year = current.year
      // var role = current.role
      var urls = ""
      // var base = 'https://erk-demo.rev.web.id/'
      var base = process.env.VUE_APP_API_URL
      if(base === 'https://mangbagja-demo.rev.web.id/'){
        base = 'https://erk-demo.rev.web.id/'
      }else{
        base = 'https://kinerja.bandung.go.id/'
      }
      if(triwulan != 'tahun'){
        urls = base+year+"/api/export-pendokumentasian-kinerja-tahunan-permenpan-6?nip="+nip+"&api=1&triwulan="+triwulan; //permenpan 6 2022
      }else{
        urls = base+year+"/api/export-pendokumentasian-kinerja-tahunan-permenpan-6?nip="+nip+"&api=1"; //permenpan 6 2022
      }
      window.open(urls,'_blank');
    },
  },
}
</script>

<style lang="css" scoped>
</style>
